import { Category, PlanDetails, PlanType, Tariffs } from "@/types/data";
import Link from "next/link";
import React from "react";

export enum SiteName {
	Solstice = "Solstice",
	SolsticeVictoria = "Solstice Victoria",
}

export const siteName = process.env.NEXT_PUBLIC_SITE as SiteName;
export const isTasmania = siteName === SiteName.Solstice;
export const isVictoria = siteName === SiteName.SolsticeVictoria;

export const categories: { [key in SiteName]: Category[] } = {
	[SiteName.Solstice]: [
		{ title: "Different Energy", value: "different-energy" },
		{ title: "Residential", value: "moving" },
		{ title: "Business", value: "business" },
		{ title: "Help & Support", value: "help-and-support" },
		{ title: "About us", value: "about-us" },
	],
	[SiteName.SolsticeVictoria]: [
		{ title: "Different Energy", value: "different-energy" },
		{ title: "Residential", value: "moving" },
		{ title: "Business", value: "business" },
		{ title: "Help & Support", value: "help-and-support" },
		{ title: "About us", value: "about-us" },
	],
};

export const Tags = {
	page: "page",
	faqItem: "faq",
	faqCategory: "faq-category",
	home: "home",
	form: "form",
	product: "product",
	404: "404",
	emergencyBanner: "emergency-banner",
};

export const ALL_CATEGORY = "all";

export const SEND_PROPERTIES = {
	SEND_FROM: "101",
	SEND_TO: "102",
	SEND_TIME: "103",
	SEND_SUBJECT: "104",
};

export const acceptedFiles = [
	"application/pdf",
	"image/png",
	"image/jpeg",
	"image/jpg",
	"image/heic",
];

const devIcons = {
	logo: "image-ef93800ebc36427a06372003865e0b79c1653103-134x169-svg",
	icon1: "image-aa9682b628135d7c6c00891192d54bdf39d9c42d-169x169-svg",
	featurePerson: "image-47e524a44a0342fc8042cc0bcad43119aa808561-500x385-jpg",
	featureBusiness: "image-ad456fdacffe6796c068076edceb9a5f52ebad8f-400x323-jpg",
};

const prodIcons = {
	logo: "image-661dc3e71b0cce13168383cfcf8eb56233d9c93f-134x169-svg",
	icon1: "image-9a4f3cc859a9b33ccf2e09f96ad45aab1faad685-169x169-svg",
	featurePerson: "image-5b34e44136a9305f6aab086453db95988a9dc65f-500x385-jpg",
	featureBusiness: "image-27ed02800ba8423dcfea5582e3aa3f44798bbecb-400x323-jpg",
};

const icons = process.env.NEXT_PUBLIC_SANITY_DATASET === "prod" ? prodIcons : devIcons;

export const GasPlans: Record<PlanType, Partial<PlanDetails> & Tariffs> = {
	PERSONAL: {
		PROD1: "Solstice Sizzle",
		PROD1_TAGLINE: "Heating things up at home",
		PROD1_FEATURE_IMAGE: icons.featurePerson,
		PROD1_LOGO: icons.logo,
		PROD1DESC1: "Flexible payment options",
		PROD1DESC2: "No transaction fees",
		PROD1DESC3: "No lock-in contracts",
		PROD1DESC4: "Paper free billing (Ebilling)",
		PROD1DESC5: "Easily manage your account online",
		PROD1_ICON1: icons.icon1,
		GAS_USAGE: "0.04854",
		GAS_DAILY: "0.645",
	},
	BUSINESS: {
		PROD1: "Solstice Serve",
		PROD1_FEATURE_IMAGE: icons.featureBusiness,
		PROD1_TAGLINE: "Serving Tasmanian business for nearly 20 years",
		PROD1_LOGO: icons.logo,
		PROD1DESC1: "Flexible payment options",
		PROD1DESC2: "No transaction fees",
		PROD1DESC3: "No lock-in contracts",
		PROD1DESC4: "Paper free billing (Ebilling)",
		PROD1DESC5: "Easily manage your account online",
		PROD1_ICON1: icons.icon1,
		GAS_USAGE_GJ: "50.695",
		GAS_DAILY: "1.626",
	},
};

interface ErrorMessage {
	title: string;
	body: React.ReactNode;
	link?: {
		title: string;
		link: string;
	};
}

export const errorMessages: Record<string, ErrorMessage> = {
	promo: {
		title: `Looks like that promo code isn’t right.`,
		body: "Try again or if it keeps happening, ",
		link: { title: "contact us", link: "/contact-us" },
	},
	generic: {
		title: "Looks like something went wrong.",
		body: "If it keeps happening, ",
		link: { title: "contact us", link: "/contact-us" },
	},
	noPlan: {
		title: `Oops! Something’s not quite right.`,
		body: (
			<>
				<p>Please try again in a few minutes.</p>
				<p>If it's still not working our customer service team can help you.</p>
				<p>
					Give us a call on 1800 750 750 or{" "}
					<Link
						href="/contact-us"
						target="_blank"
					>
						contact us here
					</Link>{" "}
					and we’ll get in touch.
				</p>
				<p>
					If you’re transferring from another retailer, have your last bill handy to speed up the
					process.
				</p>
			</>
		),
	},
};

export const siteURL =
	process.env.NEXT_PUBLIC_SITE_URL || "https://" + process.env.NEXT_PUBLIC_VERCEL_URL;
export const siteAPIURL = siteURL + "/api";
