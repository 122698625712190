import {ComponentPropsWithoutRef} from "react";
import styles from "./MaterialIcon.module.scss";
import {MaterialSymbol} from "material-symbols";
import clsx from "clsx";

interface MaterialIconProps extends ComponentPropsWithoutRef<"span"> {
	name: MaterialSymbol;
	children?: never;
}

export const MaterialIcon = ({name, className, ...props}: MaterialIconProps) => {
	return (
		<span aria-label={name} role="img" className={clsx(styles["material-icon"], className)}>
			{name}
		</span>
	);
};
