/* eslint-disable no-restricted-imports, react/forbid-elements */
import type { LinkProps } from "next/link";
import Link from "next/link";

type Props = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
	LinkProps & {
		children?: React.ReactNode;
	} & React.RefAttributes<HTMLAnchorElement>;

export const LinkSolstice = ({ children, href, ...props }: Props) => {
	const isVic = process.env.NEXT_PUBLIC_SITE === "Solstice Victoria";
	if (href?.toString()?.startsWith("https://")) {
		return (
			<Link
				href={href}
				{...props}
			>
				{children}
			</Link>
		);
	}

	return (
		<Link
			{...props}
			href={`${isVic && process.env.NODE_ENV !== "development" ? "/vic" : ""}${href?.toString()?.startsWith("/") ? "" : "/"}${href}`}
		>
			{children}
		</Link>
	);
};

export default LinkSolstice;
