import clsx from "clsx";
import styles from "./LoadingSpinner.module.scss";

interface LoadingSpinnerProps {
	className?: string;
	theme?: "light" | "dark";
	size?: "xs" | "sm" | "md" | "lg" | "xl";
}

export const LoadingSpinner = ({ className, theme = "dark", size = "md" }: LoadingSpinnerProps) => {
	return (
		<div
			className={clsx(
				styles["spinner"],
				styles[`spinner--${theme}`],
				styles[`spinner--${size}`],
				className,
			)}
		/>
	);
};
