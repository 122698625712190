"use client";

import { FieldWrapper } from "@/components/form/FieldWrapper/FieldWrapper";
import { useId } from "react";
import { RegisterOptions, useFormContext, useFormState } from "react-hook-form";
import styles from "./InputField.module.scss";

export interface InputFieldProps {
	name: string;
	label: string;
	hideLabel?: boolean;
	className?: string;
	help?: string;
	placeholder?: string;
	registerOptions?: RegisterOptions;
	type?: "text" | "tel" | "email" | "url";
	inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
	slotAfterInput?: React.ReactNode;
	ariaDescribedBy?: string;
	hideError?: boolean;
}

export const InputField = ({
	name,
	label,
	hideLabel,
	className,
	help,
	placeholder,
	registerOptions,
	inputProps,
	slotAfterInput,
	ariaDescribedBy,
	type = "text",
	hideError,
}: InputFieldProps) => {
	const id = useId();
	const helpId = id + "-help";
	const errorId = id + "-error";

	const { register } = useFormContext();
	const { errors } = useFormState();

	return (
		<FieldWrapper
			name={name}
			label={label}
			hideLabel={hideLabel}
			required={registerOptions?.required as boolean}
			labelFor={id}
			className={className}
			errorId={errorId}
			hideError={hideError}
		>
			<div className={styles["input-wrapper"]}>
				<input
					className={styles["input"]}
					placeholder={placeholder}
					id={id}
					type={type}
					aria-invalid={!!errors[name]}
					aria-required={!!registerOptions?.required}
					aria-describedby={
						[help ? helpId : "", errors[name] ? errorId : "", ariaDescribedBy]
							.filter(v => v)
							.join(" ") || undefined
					}
					{...register(name, registerOptions)}
					{...inputProps}
				/>
				{slotAfterInput}
			</div>
		</FieldWrapper>
	);
};
