import { LinkProps } from "next/link";
import styles from "./Button.module.scss";
import { ComponentPropsWithoutRef } from "react";
import clsx from "clsx";
import { MaterialIcon } from "@/components/shared/MaterialIcon/MaterialIcon";
import LinkSolstice from "@/components/shared/LinkSolstice/LinkSolstice";
import { MaterialSymbol } from "material-symbols";

export interface ButtonProps {
	label?: React.ReactNode;
	showArrow?: boolean;
	className?: string;
	innerClassName?: string;
	theme?: "primary" | "secondary" | "tertiary";
	icon?: MaterialSymbol;
}

export const Button = ({
	label,
	showArrow,
	className,
	innerClassName,
	theme = "primary",
	icon,
	...buttonProps
}: ButtonProps & ComponentPropsWithoutRef<"button">) => {
	return (
		<button
			className={clsx(styles["button-wrapper"], className)}
			{...buttonProps}
		>
			<ButtonInternal
				{...{
					label,
					showArrow,
					innerClassName,
					theme,
					icon,
				}}
			/>
		</button>
	);
};

export const ButtonLink = ({
	label,
	showArrow,
	className,
	innerClassName,
	theme = "primary",
	icon,
	...linkProps
}: ButtonProps & LinkProps & ComponentPropsWithoutRef<"a">) => {
	return (
		<LinkSolstice
			className={clsx(styles["button-wrapper"], className)}
			{...linkProps}
		>
			<ButtonInternal
				{...{
					label,
					showArrow,
					innerClassName,
					theme,
					icon,
				}}
			/>
		</LinkSolstice>
	);
};

const ButtonInternal = ({ label, showArrow, innerClassName, theme, icon }: ButtonProps) => {
	return (
		<span className={clsx(styles["button"], styles[`button--${theme}`], innerClassName)}>
			{label}
			{showArrow && (
				<div className={styles["button__arrow-wrapper"]}>
					<MaterialIcon
						name="arrow_right_alt"
						className={styles["button__arrow"]}
					/>
				</div>
			)}

			{icon && (
				<MaterialIcon
					name={icon}
					className={styles["button__icon"]}
				/>
			)}
		</span>
	);
};
