import { createClient } from "next-sanity";

export const client = createClient({
	token: process.env.SANITY_READ_TOKEN,
	projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
	dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
	useCdn: process.env.VERCEL_ENV === "production" ? false : true, // using ISR on production only
	apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION ?? "2023-03-25",
	perspective: "published",
});
