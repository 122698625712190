"use client";
import { useEffect, useRef, useState } from "react";
import { useCombobox } from "downshift";
import { DebouncedState, useDebouncedCallback } from "use-debounce";

import styles from "./AddressSearchBox.module.scss";

import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { MaterialIcon } from "../MaterialIcon/MaterialIcon";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { siteAPIURL } from "../../../utilities/constants";

type AddressSuggestion = {
	text: string;
	globalAddressKey: string;
};

interface AddressSearchBoxProps {
	defaultAddress?: string;
	onClearClicked: () => void;
	onInputChange: (value: string) => void;
	onAddressSelected: (address: any) => void;
}

export const AddressSearchBox = ({
	defaultAddress,
	onClearClicked,
	onInputChange,
	onAddressSelected,
}: AddressSearchBoxProps) => {
	const [loading, setLoading] = useState(false);
	const [addressForSearch, setAddressForSearch] = useState(
		defaultAddress ? decodeURIComponent(defaultAddress) : "",
	);
	const [addressSuggestions, setAddressSuggestions] = useState<AddressSuggestion[]>([]);

	const abortControllerRef = useRef<AbortController | null>(null);

	useEffect(() => {
		const abortController = abortControllerRef.current;
		return () => {
			// Cleanup function to abort any pending requests
			if (abortController) {
				abortController.abort();
			}
		};
	}, []);

	// Debounce callback
	const debouncedSearchAddress: DebouncedState<typeof searchAddress> = useDebouncedCallback(
		// function
		(value, onComplete) => {
			searchAddress(value, onComplete);
		},
		// delay in ms
		300,
	);

	const searchAddress = async (
		address: string,
		onComplete?: (suggestions: AddressSuggestion[]) => void,
	) => {
		// Abort any pending requests
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		setAddressForSearch(address);

		// Check if the address is long enough
		if (address.length < 3) {
			setAddressSuggestions([]);
			onComplete && onComplete([]);
			return;
		}

		// Create new abort controller
		abortControllerRef.current = new AbortController();
		setLoading(true);

		fetch(siteAPIURL + `/signup/address-search?address=${address}`, {
			signal: abortControllerRef.current.signal,
		})
			.then(response => response.json())
			.then(data => {
				// handle data
				setLoading(false);

				if (data.suggestions && data.suggestions.length > 0) {
					setAddressSuggestions(data.suggestions);
					onComplete && onComplete(data.suggestions);
				} else {
					setAddressSuggestions([]);
					onComplete && onComplete([]);
				}
			})
			.catch(error => {
				setLoading(false);
				// Handle fetch errors
				if (error.name === "AbortError") {
					// Request was aborted
				} else {
					console.error("Fetch error:", error);
				}
			});
		// const data = await response.json();

		// filter to only include addresses within TAS....

		// return data;
	};

	function clearAddress() {
		onClearClicked();
		setInputValue("");
		setAddressSuggestions([]);
		setAddressForSearch("");
	}

	async function onSelectAddress(address: AddressSuggestion | null) {
		if (!address) {
			setLoading(false);
			return;
		}

		setLoading(true);

		// format the address
		await fetch(siteAPIURL + `/signup/address-format?globalAddressKey=${address.globalAddressKey}`)
			.then(response => response.json())
			.then(data => {
				data.addressText = address.text;

				onAddressSelected(data);
				setLoading(false);
			});
	}

	const {
		isOpen,
		getMenuProps,
		getItemProps,
		getInputProps,
		getLabelProps,
		setInputValue,
		selectItem,
		inputValue,
		highlightedIndex,
	} = useCombobox({
		defaultInputValue: addressForSearch,
		items: addressSuggestions,
		itemToKey: (item: AddressSuggestion | null) => item?.globalAddressKey,
		itemToString: (item: AddressSuggestion | null) => item?.text || "",

		onInputValueChange: ({ inputValue, selectedItem }) => {
			if (inputValue !== selectedItem?.text) {
				onInputChange(inputValue);
				debouncedSearchAddress(inputValue);
			}
		},

		onSelectedItemChange: ({ selectedItem }) => {
			onSelectAddress(selectedItem);
		},
	});

	/**
	 * If a default address is provided, search it immediately and select the first result.
	 * Only allow this once
	 */
	const defaultSearchOnce = useRef(true);
	useEffect(() => {
		if (defaultSearchOnce.current) {
			defaultSearchOnce.current = false;
			if (defaultAddress) {
				searchAddress(defaultAddress, suggestions => {
					if (suggestions.length > 0) {
						selectItem(suggestions[0]);
					}
				});
			}
		}
	}, [defaultAddress, selectItem]);

	const isSuggestionsOpen = isOpen && addressForSearch;

	const { control } = useFormContext();

	return (
		<div className={styles["address"]}>
			<label
				className={styles["address__label"]}
				{...getLabelProps()}
			>
				Search for your connection address to get started
			</label>
			<div className={styles["address__input-wrapper"]}>
				<div className={styles["address__icon"]}>
					{loading ? (
						<LoadingSpinner
							className={styles["address__loading-spinner"]}
							theme="light"
							size="sm"
						/>
					) : (
						<MaterialIcon name="search" />
					)}
				</div>
				<Controller
					name="address"
					control={control}
					rules={{
						validate: (value, fieldValues) =>
							!fieldValues.dpid ? "Please select a valid address to continue" : true,
					}}
					render={({
						field: { name, onBlur, onChange, ref, value, disabled },
						fieldState: { error },
					}) => (
						<input
							className={clsx(
								styles["address__input"],
								error && styles["address__input--error"],
								isSuggestionsOpen && styles["address__input--suggestions-open"],
							)}
							{...getInputProps({
								placeholder: "Start typing your connection address",
								name: name,
								ref,
							})}
						/>
					)}
				/>

				{inputValue && (
					<button
						className={styles["address__clear"]}
						onClick={clearAddress}
						aria-label="Clear address"
						type="button"
					>
						<MaterialIcon name="close" />
					</button>
				)}
			</div>

			<ul
				{...getMenuProps({
					className: clsx(
						styles["address__suggestion-list"],
						isSuggestionsOpen && styles["address__suggestion-list--open"],
					),
				})}
			>
				{addressSuggestions.map((address, index) => (
					<li
						className={clsx(
							styles["address__suggestion"],
							index === highlightedIndex && styles["address__suggestion--highlighted"],
						)}
						key={address.globalAddressKey}
						{...getItemProps({
							item: address,
						})}
					>
						<MaterialIcon
							className={styles["address__suggestion-icon"]}
							name="location_on"
						/>
						<span>{highlightTextMatch(address.text, inputValue)}</span>
					</li>
				))}
				{addressForSearch && addressSuggestions.length === 0 && (
					<li className={styles["address__suggestion"]}>
						{loading ? "Loading results..." : "No address found"}
					</li>
				)}
			</ul>
		</div>
	);
};

function highlightTextMatch(text: string, input: string) {
	const escapedInput = input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
	// Create a regular expression that matches the sequence of words in the input
	const regex = new RegExp(escapedInput.split(" ").join("\\s+"), "gi");

	const matches = text.match(regex);
	if (!matches) return <span>{text}</span>;
	return text.split(regex).map((part, index, array) => {
		if (index < array.length - 1) {
			return (
				<span key={index}>
					{part}
					<em>{matches[index]}</em>
				</span>
			);
		}
		return part;
	});
}
