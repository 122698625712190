"use client";

import { ErrorMessage } from "@hookform/error-message";
import clsx from "clsx";
import { useFormState } from "react-hook-form";
import styles from "./FieldWrapper.module.scss";

interface FieldWrapperProps {
	children: React.ReactNode;
	name: string;
	label?: string;
	hideLabel?: boolean;
	className?: string;
	slotAfterLabel?: React.ReactNode;
	required?: boolean;
	errorId?: string;
	hideError?: boolean;
}

type LabelForOrId =
	| { label: string; labelFor: string; labelId?: never }
	| { label: string; labelFor?: never; labelId: string }
	| { label?: never; labelFor?: never; labelId?: never };

export const FieldWrapper = ({
	children,
	name,
	className,
	label,
	hideLabel,
	slotAfterLabel,
	required,
	labelFor,
	labelId,
	errorId,
	hideError,
}: FieldWrapperProps & LabelForOrId) => {
	const { errors: formErrors } = useFormState();

	const errors = formErrors[name];
	if (errors) {
		console.log({ errors });
	}

	return (
		<div className={clsx(styles["field"], errors && styles["field--error"], className)}>
			{label && (
				<div className={clsx(styles["field__header"], hideLabel && "sr-only")}>
					<label
						className={clsx(styles["field__label"], hideLabel && styles["field__label--hidden"])}
						htmlFor={labelFor}
						id={labelId}
					>
						{label}
					</label>

					{!required && <span className={styles["field__non-asterisk"]}>Optional</span>}
					{/* {slotAfterLabel} */}
				</div>
			)}

			{children}

			{!hideError && (
				<ErrorMessage
					errors={formErrors}
					name={name}
					render={({ message, messages }) => {
						return (
							(message || messages) && (
								<div
									className={styles["field__error"]}
									id={errorId}
								>
									{message && <p>{message}</p>}
									{messages &&
										Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)}
								</div>
							)
						);
					}}
				/>
			)}
		</div>
	);
};
