import clsx from "clsx";
import styles from "./CheckboxInput.module.scss";
import { forwardRef } from "react";

interface CheckboxInputProps extends React.ComponentPropsWithRef<"input"> {
	label: string;
	inputClassName?: string;
	labelClassName?: string;
}

export const CheckboxInput = forwardRef(
	(
		{ value, label, className, inputClassName, labelClassName, ...inputProps }: CheckboxInputProps,
		ref: React.ForwardedRef<HTMLInputElement>,
	) => {
		return (
			<label className={clsx(styles["checkbox"], className)}>
				<input
					ref={ref}
					value={value}
					type="checkbox"
					className={clsx(styles["checkbox__input"], inputClassName)}
					{...inputProps}
				/>

				<span className={clsx(styles["checkbox__label"], labelClassName)}>{label}</span>
			</label>
		);
	},
);
CheckboxInput.displayName = "CheckboxInput";
