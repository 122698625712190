"use client";

import { pictureSanityImageBuilder } from "@/utilities/image-helpers";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import clsx from "clsx";
import React, { ComponentPropsWithoutRef, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./Picture.module.scss";

interface SizeObject {
	width: number | 0;
	height: number | 0;
}

export type PictureSizes = Record<number, SizeObject>;

/**
 * Create a picture element a-la WPImage. Provide one source image with multiple sizes, split into multiple <source> elements per pixel density, format, and responsive size.
 */
interface PictureProps extends React.ComponentProps<"picture"> {
	sanityImage: SanityImageSource;
	alt: string | null;
	/** The default width. This will be passed to the image CDN, and also the `width` attribute of the `<img>` element */
	width: number | 0;
	/** The default height. This will be passed to the image CDN, and also the `height` attribute of the `<img>` element */
	height: number | 0;
	/** Should we crop images to size? If false, this will preserve original aspect ratio */
	crop?: boolean;
	/** Add a preload link to <head> and set `loading="eager"` */
	preload?: boolean;
	/** Set `loading="eager"` */
	eager?: boolean;
	/** An object of sizes, where the key is the breakpoint in px, as an integer:
	 * @example
	 * <Picture
	 * 	width={50}
	 * 	height={50}
	 * 	sizes={{
	 * 		480: {
	 * 			width: 100,
	 * 			height: 100,
	 * 		}
	 * 	}}
	 * />
	 */
	sizes?: PictureSizes;
	/** An array of pixel density numbers */
	pixelDensities?: number[];
	/** Apply responsive styles (width: 100%; height: auto; ) */
	responsive?: boolean;
	/** Number between 1 and 100 ) */
	quality?: number;
	className?: string;
	imgClassName?: string;
	imgProps?: Partial<ComponentPropsWithoutRef<"img">>;
	/** Any other image parameters, will be appended to the url as query parameters */
	params?: Record<string, string | number | undefined>;
}

export function Picture({
	sanityImage,
	alt = "",
	width,
	height,
	crop = !!height,
	preload = false,
	eager = false,
	sizes = {},
	pixelDensities = [1, 2],
	responsive = false,
	quality = 80,
	className,
	imgClassName,
	imgProps,
	params,
	...rest
}: PictureProps) {
	// Ensure a sizes object starts with 0 and default width/height
	sizes = { 0: { width, height }, ...sizes };

	const breakpointsPerSize = Object.keys(sizes).map(breakpoint => Number(breakpoint));
	const mimeTypes = ["webp"];

	// Generate fallback img src
	const imgSrc = pictureSanityImageBuilder(sanityImage, {
		...params,
		fit: !params?.crop && crop ? "cover" : "contain",
		quality,
		width,
		height,
	});

	const imgRef = useRef<HTMLImageElement>(null);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (imgRef.current) {
			setIsLoading(!imgRef.current.complete);
		}
	}, []);

	return !sanityImage ? null : (
		<picture
			className={clsx(
				styles["picture"],
				responsive && styles["picture--responsive"],
				isLoading && "loading",
				className,
			)}
			{...rest}
		>
			{
				// For each mime type, generate a set of <source> elements per breakpoint
				mimeTypes.map(type => (
					<React.Fragment key={type}>
						{
							// For each breakpoint, generate a <source> element
							Object.entries(sizes).map(([breakpoint, size], i) => {
								// Generate an img src for each pixel density
								const srcPerDensity = pixelDensities.map(
									density =>
										`${pictureSanityImageBuilder(sanityImage, {
											...params,
											fit: !params?.crop && crop ? "cover" : "contain",
											width: size.width,
											height: size.height,
											output: type !== "default" ? type : "webp",
											dpr: density,
											quality,
										})}`,
								);

								// From the src per pixel density array, create a srcset
								// Eg. "image.png 1x, image@2x.png 2x"
								const srcSet = srcPerDensity
									.map((src, index) => `${src} ${pixelDensities[index]}x`)
									.join(", ");

								// Generate the breakpoints media query
								let media = "";
								const nextBreakpoint = breakpointsPerSize[i + 1];
								const breakpointMin = breakpoint && `${breakpoint}px`;
								const breakpointMax = nextBreakpoint && `${nextBreakpoint - 1}px`;
								if (breakpointMin) media += `(min-width: ${breakpointMin})`;
								if (breakpointMin && breakpointMax) media += ` and `;
								if (breakpointMax) media += `(max-width: ${breakpointMax})`;

								const key = `${srcSet}-${type}-${size.width}-${size.height}-${breakpoint}`;

								// Add a preload link to <head>
								if (preload && type === "webp") {
									ReactDOM.preload(imgSrc, {
										// @ts-expect-error
										key: key,
										as: "image",
										imageSrcSet: srcSet,
										// This currently does not function. As of Next 14.2.12, media is not passed through to the link element's attributes.
										// I've submitted a PR to the React codebase to get this fixed, after which it'll need to be merged into Next.
										// Until that's merged, we'll be preloading an asset for every breakpoint - not ideal, but not world-ending.
										media: media,
										type: "image/webp",
									});
								}

								return (
									<source
										key={key}
										srcSet={srcSet}
										media={media}
										type={type !== "default" ? "image/" + type : undefined}
									/>
								);
							})
						}
					</React.Fragment>
				))
			}

			<img
				src={imgSrc}
				loading={preload || eager ? "eager" : "lazy"}
				width={width || undefined}
				height={height || undefined}
				// decoding="async"
				alt={alt ?? ""}
				className={clsx(styles["img"], imgClassName)}
				draggable={false}
				onLoad={() => setIsLoading(false)}
				ref={imgRef}
				{...imgProps}
			/>
		</picture>
	);
}
